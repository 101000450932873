<template>
  <div>
    <ImgNav />
    <DetailNavBar v-if="isReady" :title="info.title" />
    <div class="content w1400">
      <div class="left">
        <news-info v-if="isReady" :loading="loading" :data="info" />
        <turn-page
          v-if="isReady"
          :prev="prev"
          :next="next"
          @onClick="pageClk"
        />
      </div>
      <div class="right">
        <q-rcode />
        <scroll-box
          v-if="isReady"
          :option="transOpt1"
          @onClick="pageClk"
          class="mrTop20"
        />
        <scroll-box
          v-if="isReady"
          :option="transOpt2"
          @onClick="pageClk"
          class="mrTop20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge'

import NewsInfo from './delLayout/NewsInfo'
import TurnPage from './delLayout/TurnPage'

import { getAuctionNewsCheck, getPriceNewsPageNextzb,getAuctionNewsGps } from 'network/bidAuction'
import {getAuctionNewsApi} from 'network/home'

export default {
  name: 'AnalysisDel',
  data() {
    return {
      userName:"",
      fxVip:"",
      isReady: false,
      loading: false,
      queryData: this.$route.query,
      info: {},
      prev: {},
      next: {},
      transOpt1: {
        title: '相关推荐',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
      transOpt2: {
        title: '热门推荐',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
    }
  },
  created() {
    this.fxVip=localStorage.getItem('fxVip')
    this.userName = localStorage.getItem('userName')
    this.apiRes()
  },
  methods: {
    apiRes() {
      let apis = [
        this.getData(),
        this.getData(0),
        this.getData(1),
        this.getNextData(),
      ]
      this.loading = true
      Promise.all(apis).then((res) => {
        this.loading = false
        this.isReady = true
      })
    },
    pageClk(e) {
      if (this.$route.query.id != e.id) {
        let id = e.id
        let page = e.page
        let subjectId = e.subjectid
        let marketId = e.marketid
        let data = {
          num:1,
          size:1,
          productId:14953,
          userName:this.userName,
          id:e.id
          // name:1
        }
        getAuctionNewsApi(data).then((res) => {
          if(res.data&&res.data.length>0){
          this.$router.push({
            name: 'analysisDel',
            query: {
              id: e.id,
              page: e.page,
              subjectId: e.subjectId,
              marketId: e.marketId,
            },
          })
          this.queryData = e
          this.apiRes()
          }else{
            this.$Modal.warning({
              title: '提示',
              content:
                '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
            })
          }
        })
      }
    },
    /* api相关 */
    getData(num) {
      // num：0获取相关 1获取热门
      return new Promise((resolve) => {
        let data = {
          // productId: 16230,
          // marketId: this.queryData.marketId,
          id: this.queryData.id,
          size: 1,
          num: 1,
        }
        if (num == 0) {
          data.id = null
          data.size = 20
          data.subjectId = this.queryData.subjectId
        } else if (num == 1) {
          data.id = null
          data.size = 20
        }
        getAuctionNewsCheck(data).then((res) => {
          if (res.code == 0) {
            if (num == 0) {
              this.transOpt1.list = res.data
            } else if (num == 1) {
              this.transOpt2.list = res.data
            } else {
              this.info = res.data[0]
            }
            resolve()
          }
        })
      })
    },
    getNextData() {
      return new Promise((resolve) => {
        let data = {
          productId:14953,
          size:this.queryData.page,
          num:this.queryData.page,
          userName:this.userName
          // subjectId: this.queryData.subjectId,
        }
        getPriceNewsPageNextzb(data).then((res) => {
          if (res.code == 0) {
            if(res.data.length==3){
              this.prev = res.data[0]
              this.next = res.data[2]
            }else{
              if(this.queryData.page==1){
                this.next = res.data[1]
                this.prev ={}
              }else{
                this.next ={}
                this.prev = res.data[0]
              }
            }
            resolve()
          }
        })
      })
    },
  },
  components: {
    NewsInfo,
    TurnPage,
  },
}
</script>

<style scoped>
.mrTop20 {
  margin-top: 20px;
}

.left,
.right {
  margin-top: 50px;
}
.content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
</style>
